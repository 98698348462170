import * as ExcelJS from "exceljs";
import {
  genereateBasePlanilha,
  regularColunas,
  bordaArea,
} from "../uteis/Generate";

const DownloadExcelBolsistas = async (bolsistas, fileName) => {
  const modifiedBolsista = await bolsistas.map((bolsista) => ({
    ESCOLA: bolsista.escola.name.trimEnd(),
    MUNICIPIO: bolsista.escola.municipio.trimEnd(),
    REGIONAL: bolsista.escola.regional.trimEnd(),
    NOME: bolsista.nome,
    CPF: bolsista.cpf.trimEnd(),
    SERIE: bolsista.serieDescricao.trimEnd(),
    NIS: bolsista.nis,
    MATRICULA: bolsista.matricula.trimEnd(),
    BANCO: bolsista.banco.trimEnd(),
    AGENCIA: bolsista.agencia.trimEnd(),
    CONTA: bolsista.conta.trimEnd(),
    NOTAS: bolsista.notasMaioresQue6 === true ? "APTO" : "INAPTO",
    FREQUENCIA: bolsista.frequenciaPercentual > 70 ? "APTO" : "INAPTO",
    STATUS: bolsista.status,
    OBSERVAÇÃO: bolsista.observacaoUpdate,
  }));

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet("Bolsistas");
  const titulo =
    "Planilha de Registro de Bolsistas no Sistema de Gerencias de Bolsas - SGB";
  const subtitulo = "Bolsistas";
  genereateBasePlanilha(modifiedBolsista, ws, wb, titulo, subtitulo);

  // Adicionar cabeçalho
  const headers = Object.keys(modifiedBolsista[0]);
  const tb = ws.addRow(headers);

  tb.eachCell((cell, colNumber) => {
    cell.font = { bold: true };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "5080AB" }, // Azul
    };
    cell.alignment = { vertical: "middle", horizontal: "center" };
  });

  // Adicionar os dados
  modifiedBolsista.forEach((row) => {
    ws.addRow(Object.values(row));
  });

  // Ajusta a largura das colunas
  regularColunas(ws);

  // Adiciona Bordas
  bordaArea(modifiedBolsista, ws);

  // Salvar o workbook como XLSX
  var FileSaver = require("file-saver");
  const doc = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([doc]), fileName);
};

export default DownloadExcelBolsistas;
