import React from "react";
import styled from "styled-components";
import Container from "../../components/container/Container";

export const Title = styled.summary`
  
  
  text-align: left;
  font-size: 20px;
  cursor: pointer;
`;

export const Line = styled.hr`
margin-top: 10px;
margin-bottom: 10px;
`;

export const Paragraph = styled.p`
text-align: left;
  font-size: 14px;
`;

const Suporte = () => {
  return (
    <Container titulo="Suporte">
      <details>
        <Title>Onde posso tirar minhas dúvidas ou reportar algum problema?
        </Title>
        <Paragraph>
        Em caso de dúvidas ou problemas durante a utilização do sistema, você
        pode acionar o suporte por e-mail através do endereço{" "}
        <strong>suportegs@seduc.to.gov.br</strong> com as seguintes informações:
      </Paragraph>
      
      <Paragraph>
        <strong>1.</strong> Informe no assunto do e-mail o sistema em questão
        (por exemplo, o assunto seria: "SGB - problema x");
      </Paragraph>
      
      <Paragraph>
        <strong>2.</strong> Descreva detalhadamente o erro, incluindo o passo a
        passo para reproduzir o problema, se possível;
      </Paragraph>
      
      <Paragraph>
        <strong>3.</strong> Anexe uma captura de tela mostrando o erro.
      </Paragraph>
      
      </details>
      <Line/>
      <details>
        <Title>Como sei quando vai abrir para cadastrar novos bolsista?
        </Title>
        <Paragraph>
        O prazo para o cadastro de novos bolsistas será informado pela aba de notificação, indicado pelo
        ícone de <strong>envelope</strong> fechado no canto superior direito da tela.
        </Paragraph>
        
      </details>
      <Line/>
      <details>
        <Title>Para qual lista vai o bolsista recém cadastrado?
        </Title>
        <Paragraph>
          Assim que o bolsista é cadastrado seu status imediato é de <strong>Pendente</strong>, estando presente na listagem do status de Pendente.
        </Paragraph>
        
      </details>
      <Line/>
      <details>
        <Title>Porque já estou logado assim que abro, mas não consigo acessar nada?
        </Title>
        <Paragraph>
          Caso esse erro aconteça, não se preocupe. Basta <strong>sair do sistema e entrar com sua conta novamente</strong> e o sistema estará normalizado. 
        </Paragraph>
        <Paragraph>Gostaria de ressaltar que estamos trabalhando para corrigir todos os erros o mais rápido possível e por isso agradecemos a compreensão e paciência</Paragraph>
        
      </details>
      <Line/>
      <details>
        <Title>O que são as classificações de "Ativos, Pendente, Indeferido e Novos Homologados"?
        </Title>
        <Paragraph>
          <strong>Ativos</strong>: São os bolsistas que estão com frequência e nota suficiente para receber a bolsa.
        </Paragraph>
        <Paragraph>
          <strong>Pendentes</strong>: São os bolsistas que foram recém cadastrados, que já são cadastrados no sistema mas que possuem algum dado incorreto ou foram os bolsistas que tiveram edições em seus dados quando estavam presentes na lista de <strong>indeferidos</strong>.
        </Paragraph>
        <Paragraph>
          <strong>Indeferidos</strong>: São os bolsistas que foram cadastrados no sistema mas que estão com algum dos critérios para receber insuficientes (nota ou frequência) estando então irregulares no sistema e impossibilitados de receber a bolsa.
        </Paragraph>
        <Paragraph>
        <strong>Novos Homologados</strong>: São os bolsistas cadastrados e homologados no último mês que vão receber pela primeira vez.
        </Paragraph>
        
      </details>
      
    </Container>
  );
};

export default Suporte;
