import React, { useState } from "react";
import styled from "styled-components";

import { useUserContext } from "../../contexts/UserContext";
import CustomButton from "../button/Button";
import { ButtonsWrapper, Content, Overlay } from "./StyledModal";

const StyledButton = styled.button`
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  color: #fff;
  background-color: #00acc1;
  border-color: transparent;
  width: 100%;
  height: 50px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: background 0.3s ease;

  &:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
  }
`;

const ModalPagamento = ({ isOpen, onCancel, onProceed }) => {
  const { token } = useUserContext();
  const [isEnviado, setisEnviado] = useState();
  const [isEnviando, setisEnviando] = useState();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  if (!isOpen) return null;

  const fetchPagamentosBolsistas = async () => {
    try {
      let url = `https://sgb.seduc.to.gov.br/sgb/pagamento/pagarTodosBolsistas`;
      setisEnviando(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setisEnviado(response.status);
        throw new Error(response.status);
      }
      setisEnviado(response.status);
    } catch (error) {
      console.log(error);
    }
    setisEnviando(false);
  };

  let valor = 100;

  if (currentMonth === 10) {
    valor = 1000;
  }

  return (
    <Overlay>
      <Content>
        <h2>Confirmar Pagamento:</h2>
        <p className="texto">Valor a ser pago: {valor}</p>
        {isEnviando ? (
          <>
            <div className="spinner"></div>
            <p style={{ color: "orange" }}>Aguarde...</p>
          </>
        ) : (
          <>
            {isEnviado == null ? (
              <></>
            ) : (
              <>
                {isEnviado >= 200 && isEnviado < 300 ? (
                  <>
                    <p style={{ color: "green" }}>Pagamento Confirmado.</p>
                  </>
                ) : (
                  <>
                    {isEnviado >= 400 && isEnviado < 500 ? (
                      <>
                        <p style={{ color: "red" }}>Acesso Proibido.</p>
                      </>
                    ) : (
                      <>
                        {isEnviado >= 500 && isEnviado < 600 ? (
                          <>
                            <p style={{ color: "red" }}>Erro no Serviço.</p>
                          </>
                        ) : (
                          <>
                            <p style={{ color: "red" }}>
                              Código de Erro não Indentificado.
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <ButtonsWrapper>
          <StyledButton
            onClick={fetchPagamentosBolsistas}
            disabled={isEnviando}
          >
            Pagar
          </StyledButton>
          <CustomButton variant="cancel" onClick={onCancel}>
            Cancelar
          </CustomButton>
        </ButtonsWrapper>
      </Content>
    </Overlay>
  );
};

export default ModalPagamento;
