import React from "react";
import styled from "styled-components";
import { colors } from "../Colors"

const DivCampoStatus = styled.div`
  display: flex;
  justify-content: center;
`;

const CampoStatus = styled.span`
  padding: 3px 10px;
  border-radius: 10px;
  font-size: 17px;
  margin-left: 5px;
`;

const StatusField = ({ status, upperCase = false, centered = false, onClickAction }) => {
  const label = upperCase ? status.toUpperCase() : status;
  let labelColor;

  switch (status) {
    case "indeferido":
      labelColor = colors.vermelhoPrimario;
      break;
    case "ativo":
      labelColor = colors.verdePrimario;
      break;
    case "pendente":
      labelColor = colors.amareloPrimario;
      break;
    default:
      labelColor = colors.cinzaPrimario;
      break;
  }

  return (
    centered ? (
      <DivCampoStatus>
        <CampoStatus
          onClick={onClickAction}
          style={{ backgroundColor: labelColor }}
        >
          {label}
        </CampoStatus>
      </DivCampoStatus>
    ) : (
    <CampoStatus
      onClick={onClickAction}
      style={{ backgroundColor: labelColor }}
    >
      {label}
    </CampoStatus>
    )
  );
};

export default StatusField;
