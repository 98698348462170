import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import StatusField from "../../components/informationsField/StatusField";
import ModalInfo from "../../components/modal/ModalInfoEstudante";
import { formatCPF, formatDate } from "../../utils/format";
import { useDataContext } from "../../contexts/DataContext";
import Tabela from "../../components/table/Table";
import {
  IconContainerTable,
  NoDataMessage,
  StyledEditIcon,
  StyledSearchIcon,
} from "../../utils/StyledUtils";
import { useUserContext } from "../../contexts/UserContext";

const Estudantes = ({ escolaId, status }) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { bolsistas } = useDataContext();
  const [bolsistasFiltrado, setBolsistasFiltrado] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentEstudante, setCurrentEstudante] = useState(null);
  const hasAdd = user.profile === "SECRETARIO";

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleViewEstudante = useCallback(
    (id) => {
      const estudante = bolsistas.find((b) => b.id === id);
      setCurrentEstudante(estudante);
      setModalIsOpen(true);
    },
    [bolsistas]
  );

  const handleEditEstudante = useCallback(
    (id) => {
      const estudante = bolsistas.find((b) => b.id === id);
      navigate("cadastro?editar=true", { state: { previousData: estudante } });
      // if (estudante.status === "ativo") {
      //   Notify({
      //     message: `Aluno ativo não pode ser editado!`,
      //     type: ToastType.Error,
      //   });
      // } else {
      //   navigate("cadastro?editar=true", {
      //     state: { previousData: estudante },
      //   });
      // }
    },
    [bolsistas, navigate]
  );

  useEffect(() => {
    if (bolsistas.length > 0) {
      let processedBolsistas = bolsistas;

      if (escolaId) {
        processedBolsistas = processedBolsistas.filter(
          (b) => b.escola && b.escola.id.toString() === escolaId
        );
      }

      if (status) {
        if (status !== "ehNovoEstudante") {
          processedBolsistas = processedBolsistas.filter((b) => {
            if (status === "ativo") {
              return b.status === status && !b.ehNovoEstudante;
            } else {
              return b.status === status;
            }
          });
        } else {
          processedBolsistas = processedBolsistas.filter(
            (b) => (b.status === "ativo") & b.ehNovoEstudante
          );
        }
      }

      processedBolsistas = processedBolsistas.map((bolsista) => ({
        nome: bolsista.nome,
        cpf: formatCPF(bolsista.cpf),
        status: <StatusField status={bolsista.status} upperCase={true} />,
        dataCadastro: formatDate(bolsista.dataCadastro),
        actions: [
          <IconContainerTable>
            <StyledSearchIcon
              size={25}
              onClick={() => handleViewEstudante(bolsista.id)}
            />
            {(user.profile === "MULTIPROFISSIONAL" ||
              user.profile === "SECRETARIO") && (
              <StyledEditIcon
                size={25}
                onClick={() => handleEditEstudante(bolsista.id)}
              />
            )}
          </IconContainerTable>,
        ],
      }));

      setBolsistasFiltrado(processedBolsistas);
    }
  }, [
    bolsistas,
    status,
    escolaId,
    handleViewEstudante,
    user.profile,
    handleEditEstudante,
  ]);

  const header = ["NOME", "CPF", "STATUS", "DATA DE CADASTRO", "AÇÕES"];
  const search = ["nome", "cpf", "status"];

  return (
    <div>
      {bolsistasFiltrado.length !== 0 ? (
        <Tabela
          data={bolsistasFiltrado}
          header={header}
          paginated={true}
          search={search}
          actionAdd={hasAdd}
        />
      ) : (
        <NoDataMessage>Nenhum dado disponível.</NoDataMessage>
      )}
      <ModalInfo
        isOpen={modalIsOpen}
        onCancel={closeModal}
        data={currentEstudante}
      />
    </div>
  );
};

export default Estudantes;
