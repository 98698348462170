import React, { useState, useEffect, useCallback } from "react";
import { BsPerson } from "react-icons/bs";
import { colors } from "../../Colors";
import styled from "styled-components";
import { BsEnvelope } from "react-icons/bs";
import { useUserContext } from "../../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const StyledIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;
  color: ${colors.brancoPrimario};
  background-color: ${colors.azulPrimario};
  border-radius: 30px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  &:hover {
    color: ${colors.brancoPrimario};
    background-color: ${colors.azulSecundario};
    cursor: pointer;
  }
`;

const StyledIconMessage = styled.div`
  position: absolute;
  right: ${(props) => (props.$isvisible ? "105%" : "25%")};
  top: 0;
  height: 60px;
  width: 60px;
  color: ${colors.brancoPrimario};
  background-color: ${colors.azulPrimario};
  border-radius: 30px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: right 0.2s;

  &:hover {
    color: ${colors.brancoPrimario};
    background-color: ${colors.azulSecundario};
    cursor: pointer;
  }

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
     display: ${(props) => (props.$notification ? 'block' : 'none')};
  }
`;

const StyledProfileBar = styled.div`
  padding-left: 20px;
  padding-right: 10px;
  height: 60px;
  width: ${(props) => props.style.width};
  background-color: ${colors.brancoPrimario};
  border-radius: 30px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: background-color 0.3s, opacity 0.3s;
  z-index: 1;
  text-transform: uppercase;

  &:hover {
    color: #ededed;
    background-color: ${colors.azulSecundario};
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  ${(props) =>
    props.$notification &&
    `
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 20px;
      background-color: green;
      border-radius: 50%;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    }
  `}

  ${(props) =>
    !props.$isvisible &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`;

const ProfileBar = () => {
  const navigate = useNavigate();
  const [namebWidth, setNameWidth] = useState("auto");
  const { user } = useUserContext();
  const [isProfileBarVisible, setProfileBarVisible] = useState(true);
  const [isNotification, setIsNotification] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [wasExpanded, setWasExpanded] = useState(windowWidth > 1268);

  const handleIconClick = () => {
    setProfileBarVisible(!isProfileBarVisible);
  };

  const handleNotificationClick = () => {
    navigate("/notificacao");
  };

  const handleResize = useCallback(() => {
    const newWidth = window.innerWidth;
    const shouldExpand = newWidth > 1268;

    if (shouldExpand !== wasExpanded) {
      setWasExpanded(shouldExpand);
    }

    if (newWidth < 768) {
      setProfileBarVisible(false);
    }

    setWindowWidth(newWidth);
  }, [wasExpanded]);

  useEffect(() => {
    const upperCaseName = user.nomeCompleto.toUpperCase();
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "16px Arial";

    const totalWidth = context.measureText(upperCaseName).width + 40;

    setNameWidth(`${totalWidth}px`);
  }, [user.nomeCompleto]);

  useEffect(() => {
    if (windowWidth < 768) {
      setProfileBarVisible(false);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize, windowWidth]);

  useEffect(() => {
    setIsNotification(user.hasMessagesToRead);
  }, [user]);

  return (
    <Container>
      <StyledIconMessage $isvisible={isProfileBarVisible} $notification={isNotification} onClick={handleNotificationClick}>
        <BsEnvelope size={35} />
      </StyledIconMessage>
      <StyledProfileBar $isvisible={isProfileBarVisible} style={{ width: namebWidth }}>
        <span style={{ fontSize: "16px" }}>{user.nomeCompleto}</span>
        <span style={{ fontSize: "12px" }}>{user.profile}</span>
      </StyledProfileBar>
      <StyledIcon onClick={handleIconClick}>
        <BsPerson size={35} />
      </StyledIcon>
    </Container>
  );
};

export default ProfileBar;
