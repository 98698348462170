import React, { useState } from "react";
import styled from "styled-components";
import { FaRegCircle, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaRegFileExcel } from "react-icons/fa6";
import { GoVerified } from "react-icons/go";
import { CiMoneyCheck1 } from "react-icons/ci";
import { PiStudent } from "react-icons/pi";
import { RxUpdate } from "react-icons/rx";
import { BsClipboard2Check } from "react-icons/bs";
import { LuSchool } from "react-icons/lu";
import { GrSystem } from "react-icons/gr";
import {
  MdOutlineDashboard,
  MdOutlineInfo,
  MdOutlineVerified,
  MdLogout,
  MdSupportAgent,
  MdHome,
  MdOutlinePendingActions,
  MdOutlineBlock,
  MdNoteAdd,
} from "react-icons/md";

import { useUserContext } from "../../../contexts/UserContext";

const StyledSidebar = styled.div`
  width: ${(props) => (props.$expanded ? "250px" : "100px")};
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #04294b;
  color: #ecf0f1;
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
  border-right: 1px solid #dee2e6;
`;

const SidebarHeader = styled.div`
  background-color: #043c70;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
  margin-bottom: 10px;
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.3);
`;

const SidebarBrand = styled.div`
  display: flex;
  align-items: center;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #34495e;
  }
  &::-webkit-scrollbar-thumb {
    background: #34495e;
    &:hover {
      background: #34495e;
    }
  }
  scrollbar-width: thin;
  scrollbar-color: #1f2c38 #34495e;
`;

const SidebarFooter = styled.div`
  background-color: #043c70;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.3);
  @media (min-width: 768px) {
    display: flex;
  }
`;

const SidebarItemContainer = styled.li`
  padding: 10px;
  margin: 0px 10px;
  font-size: 1.2em;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$expanded ? "flex-start" : "center")};
  transition: background-color 0.3s;

  &:hover {
    background-color: #043c70;
    border-radius: 8px;
  }
`;

const SidebarSubItemContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  display: ${(props) => (props.$expanded ? "block" : "none")};
`;

const SidebarItemIcon = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const SidebarItemText = styled.span`
  display: ${(props) => (props.$expanded ? "inline" : "none")};
  align-items: center;
`;

const SidebarSectionTitle = styled.li`
  padding: 10px;
  color: #adb5bd;
  text-transform: uppercase;
  margin-top: 20px;
  text-align: left;
`;

const Spacer = styled.div`
  height: 1px;
  background-color: #ebebeb;
  margin: 35px 20px 20px 20px;
`;

const SidebarItem = ({ icon, title, texto, path, children, expanded }) => {
  const navigate = useNavigate();
  const { setUser, setToken } = useUserContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    if (children) {
      setIsExpanded(!isExpanded);
    } else if (path) {
      if (path === "/login") {
        localStorage.clear();
        setUser(null);
        setToken(null);
      }
      navigate(path);
    }
  };

  return (
    <div>
      {title &&
        (expanded ? (
          <SidebarSectionTitle>{title}</SidebarSectionTitle>
        ) : (
          <Spacer />
        ))}
      <SidebarItemContainer onClick={handleToggle} $expanded={expanded}>
        <SidebarItemIcon>{icon}</SidebarItemIcon>
        <SidebarItemText $expanded={expanded}>{texto}</SidebarItemText>
      </SidebarItemContainer>
      {children && (
        <SidebarSubItemContainer $expanded={isExpanded}>
          {children.map((child, index) => (
            <SidebarSubItem key={index} {...child} expanded={expanded} />
          ))}
        </SidebarSubItemContainer>
      )}
    </div>
  );
};

const SidebarSubItem = ({ icon, texto, path, expanded }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <SidebarItemContainer onClick={handleClick}>
      <SidebarItemIcon>{icon}</SidebarItemIcon>
      <SidebarItemText $expanded={expanded}>{texto}</SidebarItemText>
    </SidebarItemContainer>
  );
};

const Sidebar = ({ expanded, onToggle }) => {
  const { user } = useUserContext();

  const handleToggle = () => {
    onToggle();
  };

  let nomeRegional;

  switch (user.regional) {
    case "ARAGUAINA":
      nomeRegional = "Araguaína";
      break;
    case "ARAGUATINS":
      nomeRegional = "Araguatins";
      break;
    case "ARRAIAS":
      nomeRegional = "Arraias";
      break;
    case "COLINAS":
      nomeRegional = "Colinas do Tocantins";
      break;
    case "DIANOPOLIS":
      nomeRegional = "Dianópolis";
      break;
    case "GUARAI":
      nomeRegional = "Guaraí";
      break;
    case "GURUPI":
      nomeRegional = "Gurupi";
      break;
    case "MIRACEMA":
      nomeRegional = "Miracema do Tocantins";
      break;
    case "PALMAS":
      nomeRegional = "Palmas";
      break;
    case "PARAISO":
      nomeRegional = "Paraíso do Tocantins";
      break;
    case "PEDROAFONSO":
      nomeRegional = "Pedro Afonso";
      break;
    case "PORTONACIONAL":
      nomeRegional = "Porto Nacional";
      break;
    case "TOCANTINOPOLIS":
      nomeRegional = "Tocantinópolis";
      break;
    default:
      nomeRegional = "Regional não encontrada";
  }

  let sidebarItens = [];
  let escolaNome;

  const renderSidebar = () => {
    switch (user.profile) {
      case "ADMIN":
        escolaNome = user.setor;
        sidebarItens = [
          {
            icon: <MdOutlineDashboard size={30} />,
            texto: "Dashboard",
            path: "/dashboard",
          },
          { icon: <LuSchool size={30} />, texto: "Escolas", path: "/escolas" },
          {
            icon: <PiStudent size={30} />,
            texto: "Estudantes",
            path: "/estudantes",
          },
          {
            icon: <CiMoneyCheck1 size={30} />,
            texto: "Pagamento",
            path: "/pagamento",
          },
          {
            title: "Sistema",
            icon: <FaUsers size={30} />,
            texto: "Usuários",
            path: "/usuarios",
          },
          {
            icon: <RxUpdate size={30} />,
            texto: "Atualizações",
            path: "/atualizacoes",
          },
          {
            icon: <GrSystem size={30} />,
            texto: "Cadastro",
            children: [
              {
                icon: <FaRegCircle size={7} />,
                texto: "Administrador",
                path: "cadastro/administrador",
              },
              {
                icon: <FaRegCircle size={7} />,
                texto: "Avaliador (SRE)",
                path: "cadastro/avaliador-sre",
              },
              {
                icon: <FaRegCircle size={7} />,
                texto: "Escola",
                path: "cadastro/escola",
              },
              {
                icon: <FaRegCircle size={7} />,
                texto: "Multiprofissional",
                path: "cadastro/multiprofissional",
              },
              {
                icon: <FaRegCircle size={7} />,
                texto: "Secretário",
                path: "cadastro/secretario",
              },
            ],
          },
          {
            title: "Download",
            icon: <FaRegFileExcel size={30} />,
            texto: "Planilhas",
            path: "/planilhas",
          },
        ];
        break;
      case "SECRETARIO":
        escolaNome = user.escola.name;
        sidebarItens = [
          {
            title: "Estudantes",
            icon: <PiStudent size={30} />,
            texto: "Estudantes Geral",
            path: "/estudantes",
          },
          {
            icon: <GoVerified size={30} />,
            texto: "Ativos",
            path: "/ativos",
          },
          {
            icon: <BsClipboard2Check size={30} />,
            texto: "Novos Homologados",
            path: "/novos-homologados",
          },
          {
            icon: <MdOutlinePendingActions size={30} />,
            texto: "Pendentes",
            path: "/pendentes",
          },
          {
            icon: <MdOutlineBlock size={30} />,
            texto: "Indeferidos",
            path: "/indeferidos",
          },
          {
            icon: <MdNoteAdd size={30} />,
            texto: "Cadastro",
            path: "/cadastro/estudante",
          },
        ];
        break;
      case "MULTIPROFISSIONAL":
        escolaNome = user.escola.name;
        sidebarItens = [
          {
            icon: <MdOutlineDashboard size={30} />,
            texto: "Dashboard",
            path: "/dashboard",
          },
          {
            title: "Download",
            icon: <FaRegFileExcel size={30} />,
            texto: "Planilhas",
            path: "/planilhas",
          },
          {
            title: "Estudantes",
            icon: <PiStudent size={30} />,
            texto: "Estudantes Geral",
            path: "/estudantes",
          },
          {
            icon: <GoVerified size={30} />,
            texto: "Ativos",
            path: "/ativos",
          },
          {
            icon: <BsClipboard2Check size={30} />,
            texto: "Novos Homologados",
            path: "/novos-homologados",
          },
          {
            icon: <MdOutlinePendingActions size={30} />,
            texto: "Pendentes",
            path: "/pendentes",
          },
          {
            icon: <MdOutlineBlock size={30} />,
            texto: "Indeferidos",
            path: "/indeferidos",
          },
        ];
        break;
      case "AVALIADOR":
        escolaNome = nomeRegional;
        sidebarItens = [
          {
            icon: <MdOutlineDashboard size={30} />,
            texto: "Dashboard",
            path: "/dashboard",
          },
          { icon: <LuSchool size={30} />, texto: "Escolas", path: "/escolas" },
          {
            title: "Download",
            icon: <FaRegFileExcel size={30} />,
            texto: "Planilhas",
            path: "/planilhas",
          },
          {
            title: "Estudantes",
            icon: <PiStudent size={30} />,
            texto: "Estudantes Geral",
            path: "/estudantes",
          },
          {
            icon: <GoVerified size={30} />,
            texto: "Ativos",
            path: "/ativos",
          },
          {
            icon: <BsClipboard2Check size={30} />,
            texto: "Novos Homologados",
            path: "/novos-homologados",
          },
          {
            icon: <MdOutlinePendingActions size={30} />,
            texto: "Pendentes",
            path: "/pendentes",
          },
          {
            icon: <MdOutlineBlock size={30} />,
            texto: "Indeferidos",
            path: "/indeferidos",
          },
        ];
        break;
      default:
        escolaNome = "Regional não encontrada";
        sidebarItens = [
          {
            icon: <MdOutlineVerified size={30} />,
            texto: "Certificados",
            path: "/certificados",
          },
        ];
    }
  };

  renderSidebar();

  return (
    <StyledSidebar $expanded={expanded && true}>
      <SidebarHeader onClick={handleToggle}>
        <SidebarBrand>{escolaNome}</SidebarBrand>
      </SidebarHeader>
      <MenuList>
        <SidebarItem
          icon={<MdHome size={30} />}
          texto="Página Inicial"
          path="/home"
          expanded={expanded}
        />

        {sidebarItens.map((item, index) => (
          <SidebarItem key={index} {...item} expanded={expanded} />
        ))}

        {expanded ? (
          <SidebarSectionTitle>Extras</SidebarSectionTitle>
        ) : (
          <Spacer />
        )}
        {/* <SidebarItem
          icon={<FaFilePdf size={30} />}
          texto="Relatórios"
          path="/relatorios"
          expanded={expanded}
        /> */}
        <SidebarItem
          icon={<MdOutlineInfo size={30} />}
          texto="Sobre"
          path="/sobre"
          expanded={expanded}
        />
      </MenuList>
      <SidebarFooter>
        <SidebarItem
          icon={<MdSupportAgent size={30} />}
          texto="Suporte"
          path="/suporte"
          expanded={expanded}
        />
        <SidebarItem
          icon={<MdLogout size={30} />}
          texto="Sair"
          path="/login"
          expanded={expanded}
        />
      </SidebarFooter>
    </StyledSidebar>
  );
};

export default Sidebar;
