import React from 'react'

// ícones
import { MdOutlineDashboard, MdOutlinePendingActions, MdOutlineBlock } from "react-icons/md";
import { FaRegFileExcel } from "react-icons/fa6";
import { GoVerified } from "react-icons/go";
import { BsClipboard2Check } from "react-icons/bs";
import { LuSchool } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { PiStudent } from 'react-icons/pi';

//components
import CardGroup from "../../components/card/CardGroup";
import { formatNumberWithDot } from '../../utils/format';

const HomeAvaliador = ({ totalAtivos, totalNovosAtivos, totalIndeferido, totalPendente, totalEscolas, totalBolsistas }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  const itens = [
    {
      id: 0,
      title: "Ativos",
      variant: "green",
      type: "titleIconValue",
      value: formatNumberWithDot(totalAtivos),
      icon: GoVerified,
      buttonAction: () => {
        handleClick("/ativos");
      },
    },
    {
      id: 1,
      title: "Novos Homologados",
      variant: "oceanBlue",
      type: "titleIconValue",
      value: formatNumberWithDot(totalNovosAtivos),
      icon: BsClipboard2Check,
      buttonAction: () => {
        handleClick("/novos-homologados");
      },
    },
    {
      id: 2,
      title: "Pendentes",
      variant: "yellow",
      type: "titleIconValue",
      value: formatNumberWithDot(totalPendente),
      icon: MdOutlinePendingActions,
      buttonAction: () => {
        handleClick("/pendentes");
      },
    },
    {
      id: 3,
      title: "Indeferidos",
      variant: "red",
      type: "titleIconValue",
      value: formatNumberWithDot(totalIndeferido),
      icon: MdOutlineBlock,
      buttonAction: () => {
        handleClick("/indeferidos");
      },
    },
    {
      id: 4,
      title: "Estudantes Geral",
      variant: "blue",
      type: "titleIconValue",
      value: formatNumberWithDot(totalBolsistas),
      icon: PiStudent,
      buttonAction: () => {
        handleClick("/estudantes");
      },
    },
    {
      id: 5,
      title: "Dashboard",
      variant: "blue",
      type: "titleIcon",
      icon: MdOutlineDashboard,
      buttonAction: () => {
        handleClick("/dashboard");
      },
    },
    {
      id: 6,
      title: "Planilhas",
      variant: "blue",
      type: "titleIcon",
      icon: FaRegFileExcel,
      buttonAction: () => {
        handleClick("/planilhas");
      },
    },
    {
      id: 7,
      title: "Escolas",
      variant: "blue",
      type: "titleIconValue",
      value: formatNumberWithDot(totalEscolas),
      icon: LuSchool,
      buttonAction: () => {
        handleClick("/escolas");
      },
    },
    // {
    //   id: 8,
    //   title: "Relatórios",
    //   variant: "deactivate",
    //   type: "titleIcon",
    //   icon: FaRegFileLines,
    //   buttonAction: () => {
    //     handleClick("/relatorios");
    //   },
    // },
  ];
  return (
    <CardGroup menuItems={itens}/>
  )
}

export default HomeAvaliador
