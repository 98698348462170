import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import Container from "../../components/container/Container"
import { useDataContext } from "../../contexts/DataContext";
import CustomButton from "../../components/button/Button";
import styled from 'styled-components';
import { StyledEditIcon } from "../../utils/StyledUtils";

const Label = styled.label`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: .5em;
`;

const Input = styled.input`
  font-size: 1.4em;
  width: 100% !important;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #ebebeb;
  margin-top: .2em;
  padding: 0;
  height: 35px;

  &:disabled {
    background-color: #f1f1f1;
  }
`;

const InputRow = styled.div`
  display: grid;
  align-items: center;
  margin-bottom: 10px;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const InputDate = styled.input`
  font-size: 1.4em;
  width: 100% !important;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #ebebeb;
  margin-top: .2em;
  padding: 0;
  height: 35px;

  &[type='date']:not(:focus):in-range::-webkit-datetime-edit-year-field,
  &[type='date']:not(:focus):in-range::-webkit-datetime-edit-month-field,
  &[type='date']:not(:focus):in-range::-webkit-datetime-edit-day-field,
  &[type='date']:not(:focus):in-range::-webkit-datetime-edit-text {
    color: transparent;
  }

  &[type='date']:empty {
    background-color: #ebebeb;
  }

  &::-webkit-date-and-time-value {
    text-align: left;
    color: var(--sg-black);
  }

  &[type='date']:not(:focus):empty:after {
    position: absolute;
    color: #aaa;
    content: attr(placeholder);
  }

  &:disabled {
    background-color: #f1f1f1;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

export const FieldLabel = styled.div`
  font-size: 1em;
  color: #888;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: left;
`;

const InputTitle = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  margin-left: 30%;
  width: 35%;
`;

const InputButton = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 60%;
  margin-top: 20px;
  margin-left: 20%;
`;

const CheckboxRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  grid-column: 3 / span 4;
  margin-top: 20px;
`;

const InputSelect = styled.select`
  display: grid;
  width: 100% !important;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  grid-column: 1 / span 2;f1f1f1
  font-size: 1.4em;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #ebebeb;
  margin-top: .2em;
  padding: 0;
  height: 35px;

  &:disabled {
    background-color: #f1f1f1;
  }

`;

const CheckboxInput = styled.input`
  width: 5em;
  height: 1.2rem;
  transform: scale(0.80);
  border-radius: 0.5rem;
`;

const ConfigurarParametro = () => {
  const { user } = useUserContext();
  const { configuracao, mutationPutConfiguracao } = useDataContext();
  const [checkedNota, setCheckedNota] = useState(true);
  const [checkedFrequencia, setCheckedFrequencia] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    primeiroBimestrePrazo: configuracao.primeiroBimestrePrazo,
    segundoBimestrePrazo: configuracao.segundoBimestrePrazo,
    terceiroBimestrePrazo: configuracao.terceiroBimestrePrazo,
    quartoBimestrePrazo: configuracao.quartoBimestrePrazo,
    notaMedia: configuracao.notaMedia,
    aceitaNotaNula: configuracao.aceitaNotaNula,
    aceitaFrequenciaNula: configuracao.aceitaFrequenciaNula,
    percentalFrequencia: configuracao.percentalFrequencia,
    diaMes: configuracao.diaMes,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const intValue = parseInt(value, 10);
    const intEnd = name === 'notaMedia' ? 10 : 100;

    if (!isNaN(intValue) && intValue >= 0 && intValue <= intEnd) {
      setFormData({
        ...formData,
        [name]: intValue,
      });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const intValue = parseInt(value, 10);
    const intEnd = name === 'notaMedia' ? 10 : 100;

    if (isNaN(intValue) || intValue < 0 || intValue > intEnd) {
      setFormData({
        ...formData,
        [name]: '',
      });
    } else {
      setFormData({
        ...formData,
        [name]: intValue,
      });
    }
  };

  const handleCheckboxNotaChange = () => {
    setCheckedNota(!checkedNota)
    setFormData({
      ...formData,
      aceitaNotaNula: checkedNota,
    });
  };

  const handleCheckboxFrequenciaChange = () => {
    setCheckedFrequencia(!checkedFrequencia)
    setFormData({
      ...formData,
      aceitaFrequenciaNula: checkedFrequencia,
    });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = async () => {
    try {
      await mutationPutConfiguracao.mutateAsync(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const onCancel = () => {
    navigate('/pagamento');
  };

  const handleSubmitEventDefault = (e) => {
    e.preventDefault();
    handleSubmit();
  };


  return (
    user &&
      <Container style={{width: '50%', padding: '0 50px 10px'}}>
          <InputTitle>
            <h2>Configurar Sistema</h2>
            <StyledEditIcon onClick={handleEditClick} />
          </InputTitle>
          <form onSubmit={handleSubmitEventDefault}>
            <div>
              <FieldLabel>Data final de Bimestre</FieldLabel>
              <InputRow>
                <div>
                  <Label htmlFor="primeiroBimestrePrazo">1º Bimestre</Label>
                  <InputDate
                    id="primeiroBimestrePrazo"
                    type="date"
                    value={formData.primeiroBimestrePrazo}
                    onChange={handleDateChange}
                    disabled={!isEditing}
                  />
                </div>
                <div>
                  <Label htmlFor="segundoBimestrePrazo">2º Bimestre</Label>
                  <InputDate
                    id="segundoBimestrePrazo"
                    type="date"
                    value={formData.segundoBimestrePrazo}
                    onChange={handleDateChange}
                    disabled={!isEditing}
                  />
                </div>
                <div>
                  <Label htmlFor="terceiroBimestrePrazo">3º Bimestre</Label>
                  <InputDate
                    id="terceiroBimestrePrazo"
                    type="date"
                    value={formData.terceiroBimestrePrazo}
                    onChange={handleDateChange}
                    disabled={!isEditing}
                  />
                </div>
                <div>
                  <Label htmlFor="quartoBimestrePrazo">4º Bimestre</Label>
                  <InputDate
                    id="quartoBimestrePrazo"
                    type="date"
                    value={formData.quartoBimestrePrazo}
                    onChange={handleDateChange}
                    disabled={!isEditing}
                  />
                </div>
              </InputRow>
            </div>
            <div>
              <FieldLabel>Notas</FieldLabel>
              <InputRow>
                <div style={{marginTop: '0px', paddingTop: '0'}}>
                  <Label htmlFor="notaMedia">Valor da nota</Label>
                  <div>
                    <Input
                      id="notaMedia"
                      type="number"
                      value={formData.notaMedia}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      disabled={!isEditing || checkedNota === true}
                    />
                  </div>
                </div>
                <CheckboxRow onClick={() => {if (isEditing) {handleCheckboxNotaChange();}}}>
                  <CheckboxInput
                    type="checkbox"
                    id="aceitaNotaNula"
                    checked={checkedNota}
                    value={checkedNota}
                    disabled={!isEditing }
                    readOnly
                  />
                  <Label htmlFor="aceitaNotaNula">Aceitar nota nula</Label>
                </CheckboxRow>
              </InputRow>
            </div>
            <div>
              <FieldLabel>Frequência</FieldLabel>
              <InputRow>
                <div>
                  <Label htmlFor="percentalFrequencia">Porcentagem frequência</Label>
                  <Input
                    id="percentalFrequencia"
                    type="number"
                    value={formData.percentalFrequencia}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    disabled={!isEditing || checkedFrequencia === true}
                  />
                </div>
                <CheckboxRow onClick={() => {if (isEditing) {handleCheckboxFrequenciaChange();}}}>
                  <CheckboxInput
                    type="checkbox"
                    id="aceitaFrequenciaNula"
                    style={{ borderRadius: "50%" }}
                    checked={checkedFrequencia}
                    value={checkedFrequencia}
                    disabled={!isEditing }
                    readOnly
                  />
                  <Label htmlFor="aceitaFrequenciaNula">Aceitar frequência nula</Label>
                </CheckboxRow>
              </InputRow>
            </div>
            <div>
              <FieldLabel>Data de encerramento das inscrições</FieldLabel>
              <InputRow>
                <div>
                  <Label htmlFor="diaMes">Dia do mês</Label>
                  <InputSelect
                    id="diaMes"
                    onChange={handleSelectChange}
                    value={formData.diaMes}
                    disabled={!isEditing}
                  >
                    <option value=""></option>
                    {[...Array(31).keys()].map(i => (
                      <option key={i + 1} value={i + 1}>{i + 1}</option>
                    ))}
                  </InputSelect>
                </div>
              </InputRow>
            </div>
            <InputButton>
              <CustomButton disabled={!isEditing}>Salvar</CustomButton>
              <CustomButton variant="cancel" onClick={onCancel}>Cancelar</CustomButton>
            </InputButton>
          </form>
      </Container>
  );
};

export default ConfigurarParametro;
