import { Notify, ToastType } from "../components/notify/notify";

/**
 * Busca a lista de bolsistas.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Array>} - A lista de bolsistas.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchBolsistas = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/bolsista`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Busca a lista de escolas.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Array>} - A lista de escolas.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchEscolas = async (token) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/escola`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `Erro ao carregar dados de escolas.`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Busca o total de bolsistas.
 * @param {string} token - O token de autorização.
 * @returns {Promise<number>} - O total de bolsistas.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchTotalBolsistas = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/bolsista/totalBolsistas`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `Erro ao carregar total de bolsistas.`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Busca o total de escolas.
 * @param {string} token - O token de autorização.
 * @returns {Promise<number>} - O total de escolas.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchTotalEscolas = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/escola/totalEscolas`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `Erro ao carregar dados de escolas.`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Busca perfis de usuários.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Array>} - A lista de perfis de usuários.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchProfiles = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/perfilUsuario`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = `Erro ao carregar dados de perfis.`;
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });
    console.error(errorMessage);

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Deleta um perfil de usuário.
 * @param {string} token - O token de autorização.
 * @param {string} id - O ID do perfil de usuário a ser deletado.
 * @returns {Promise<Object|null>} - O resultado da deleção ou null se a resposta estiver vazia.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const deleteProfile = async (token, id) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/perfilUsuario/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Remoção de perfil bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  const responseData = await response.text();
  return responseData ? JSON.parse(responseData) : null;
};

/**
 * Busca escolas pelo código INEP.
 * @param {string} token - O token de autorização.
 * @param {string} inep - O código INEP da escola.
 * @returns {Promise<Object>} - Os dados da escola.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchEscolasInep = async (token, inep) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/escola/searchInep/${inep}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Busca municípios do Tocantins.
 * @returns {Promise<Array>} - A lista de municípios.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchMunicipios = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_IBGE_URL}/localidades/estados/TO/municipios`,
    {
      method: "GET",
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Busca a configuração.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Object>} - A configuração.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchConfiguracao = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/configuracao`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  const responseData = await response.text();
  return JSON.parse(responseData);
};

/**
 * Cria um novo perfil de usuário.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados do novo perfil de usuário.
 * @returns {Promise<Object>} - Os dados do perfil criado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const postProfile = async (token, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/perfilUsuario`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({ message: `Cadastro bem-sucedido!`, type: ToastType.Success });
  }

  return response.json();
};

/**
 * Atualiza um perfil de usuário.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados de usuário.
 * @returns {Promise<Object>} - Os dados de usuário atualizado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const putProfile = async (token, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/perfilUsuario/editar/${data.id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Alteração de perfil bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

/**
 * Atualiza uma configuração.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados da nova configuração.
 * @returns {Promise<Object>} - Os dados da configuração atualizada.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const putConfiguracao = async (token, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/configuracao`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Alteração em configurações bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

/**
 * Cria uma nova escola.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados da nova escola.
 * @returns {Promise<Object>} - Os dados da escola criada.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const postEscola = async (token, data) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/escola`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Cadastro de escola bem-sucedido!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

/**
 * Atualiza uma escola.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados da escola.
 * @returns {Promise<Object>} - Os dados da escola atualizado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const putEscola = async (token, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/escolas/${data.id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Alteração de escola bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

/**
 * Deleta uma escola.
 * @param {string} token - O token de autorização.
 * @param {string} id - O ID da escola a ser deletada.
 * @returns {Promise<Object|null>} - O resultado da deleção ou null se a resposta estiver vazia.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const deleteEscola = async (token, id) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/escolas/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Remoção de escola bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  const responseData = await response.text();
  return responseData ? JSON.parse(responseData) : null;
};

/**
 * Cria um novo bolsista.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados da nova bolsista.
 * @returns {Promise<Object>} - Os dados da bolsista criada.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const postBolsista = async (token, data) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/bolsista`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Cadastro de estudante bem-sucedido!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

/**
 * Atualiza um bolsista.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados do bolsista.
 * @returns {Promise<Object>} - Os dados do bolsista atualizado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const putBolsista = async (token, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/bolsista/${data.id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Alteração em estudante bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

/**
 * Deleta um bolsista.
 * @param {string} token - O token de autorização.
 * @param {string} id - O ID do bolsista a ser deletado.
 * @returns {Promise<Object|null>} - O resultado da deleção ou null se a resposta estiver vazia.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const deleteBolsista = async (token, id) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/bolsista/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Remoção de estudante bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  const responseData = await response.text();
  return responseData ? JSON.parse(responseData) : null;
};

/**
 * Busca lista de mensagens.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Object>} - As mensagens.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchMensagens = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/mensagem`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Erro HTTP! Status: ${response.status}`);
  }

  const responseData = await response.text();
  return JSON.parse(responseData);
};

/**
 * Atualizar mensagem.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados do mensagem.
 * @returns {Promise<Object>} - Os dados do mensagem atualizado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const putMensagem = async (token, data) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/mensagem/${data.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(`Erro HTTP! Status: ${response.status}`);
  }

  const responseData = await response.text();
  return JSON.parse(responseData);
};

/**
 * Atualiza uma mensagem.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados da nova mensagem.
 * @returns {Promise<Object>} - Os dados da mensagem criada.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const postMensagem = async (token, data) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/mensagem`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(`Erro HTTP! Status: ${response.status}`);
  }

  const responseData = await response.text();
  return JSON.parse(responseData);
};

/**
 * Deleta uma mensagem.
 * @param {string} token - O token de autorização.
 * @param {string} id - O ID da mensagem a ser deletado.
 * @returns {Promise<Object|null>} - O resultado da deleção ou null se a resposta estiver vazia.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const deleteMensagem = async (token, id) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/mensagem/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Erro HTTP! Status: ${response.status}`);
  }

  const responseData = await response.text();
  if (responseData) {
    return JSON.parse(responseData);
  } else {
    return null;
  }
};


/**
 * Busca de audits.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Array>} - A lista de audits.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const fetchAudits = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/audit`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Cria um novo audit.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Object>} - Os dados do audit criado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const postAudit = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/audit`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Cadastro de audit bem-sucedido!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

