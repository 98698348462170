import React from "react";
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
  useParams,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// pages
import Home from "./pages/home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import Planilhas from "./pages/planilhas/Planilhas";
import Pagamento from "./pages/pagamento/Pagamento";
import ConfigurarParametro from "./pages/pagamento/ConfigurarParametro";
import Escolas from "./pages/escolas/Escolas";
import Estudantes from "./pages/estudantes/Estudantes";
import Cadastros from "./pages/cadastros/Cadastros";
import Administrador from "./pages/cadastros/components/users/Administrador";
import Avaliador from "./pages/cadastros/components/users/Avaliador";
import Escola from "./pages/cadastros/components/escola/Escola";
import Multiprofissional from "./pages/cadastros/components/users/Multiprofissional";
import Secretario from "./pages/cadastros/components/users/Secretario";
import Relatorios from "./pages/relatorios/Relatorios";
import UsuariosLista from "./pages/sistema/UsuariosLista";
import Sobre from "./pages/sobre/Sobre";
import Suporte from "./pages/suporte/Suporte";
import NotFoundPage from "./pages/404/404";
import Estudante from "./pages/cadastros/components/estudante/Estudante";

// components
import Login from "./Login";
import LayoutSidebar from "./components/layouts/LayoutSidebar";
import LayoutData from "./components/layouts/LayoutData";
import LayoutContext from "./components/layouts/LayoutContext";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import RedirectIfAuthenticated from "./components/routes/RedirectIfAuthenticated";

import { useDataContext } from "./contexts/DataContext";
import { slugify } from "./utils/slugify";
import "./App.css";
import Notifications from "./pages/notifications/Notifications";
import Mensagem from "./pages/notifications/components/Mensagem";
import MensagemView from "./pages/notifications/components/MensagemView";
import Audit from "./pages/sistema/Audit";

const EscolaWrapper = () => {
  const { escolas } = useDataContext();
  const { slug } = useParams();

  const escola = escolas.find((e) => slugify(e.name) === slug);

  if (!escola) {
    return <NotFoundPage />;
  }

  return <Estudantes escolaId={escola.id.toString()} />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route element={<LayoutContext />}>
        <Route
          path="/login"
          element={
            <RedirectIfAuthenticated>
              <Login />
            </RedirectIfAuthenticated>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <LayoutSidebar />
            </ProtectedRoute>
          }
        >
          <Route element={<LayoutData />}>
            <Route path="/home" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/planilhas" element={<Planilhas />} />
            {/* Sessão de Notificação */}
            <Route path="notificacao">
              <Route index element={<Notifications />} />
              <Route path="cadastro" element={<Mensagem />} />
              <Route path="cadastro?editar=true" element={<Mensagem />} />
              <Route path="visualizar" element={<MensagemView />} />
            </Route>
            {/* Sessão de Pagamento */}
            <Route path="pagamento">
              <Route index element={<Pagamento />} />
              <Route
                path="configurar-parametro"
                element={<ConfigurarParametro />}
              />
            </Route>
            {/* Sessão de Cadastro */}
            <Route path="cadastro">
              <Route index element={<Cadastros />} />
              <Route path="administrador" element={<Administrador />} />
              <Route path="avaliador-sre" element={<Avaliador />} />
              <Route path="escola" element={<Escola />} />
              <Route path="multiprofissional" element={<Multiprofissional />} />
              <Route path="secretario" element={<Secretario />} />
              <Route path="estudante" element={<Estudante />} />
            </Route>
            {/* Sessão de Sistema */}
            <Route path="/usuarios" element={<UsuariosLista />} />
            <Route path="/atualizacoes" element={<Audit />} />

            {/* Sessão de Escolas */}
            <Route path="/escolas">
              <Route index element={<Escolas />} />
              <Route path="cadastro" element={<Escola />} />
              <Route path="cadastro?editar=true" element={<Escola />} />
              <Route path=":slug" element={<EscolaWrapper />} />
            </Route>

            {/* Sessão de Estudantes */}
            <Route path="/estudantes">
              <Route index element={<Estudantes />} />
              <Route path="cadastro" element={<Estudante />} />
              <Route path="cadastro?editar=true" element={<Estudante />} />
            </Route>

            <Route path="/ativos">
              <Route index element={<Estudantes status="ativo" />} />
              <Route path="cadastro" element={<Estudante />} />
              <Route path="cadastro?editar=true" element={<Estudante />} />
            </Route>

            <Route path="/novos-homologados">
              <Route index element={<Estudantes status="ehNovoEstudante" />} />
              <Route path="cadastro" element={<Estudante />} />
              <Route path="cadastro?editar=true" element={<Estudante />} />
            </Route>

            <Route path="/pendentes">
              <Route index element={<Estudantes status="pendente" />} />
              <Route path="cadastro" element={<Estudante />} />
              <Route path="cadastro?editar=true" element={<Estudante />} />
            </Route>

            <Route path="/indeferidos">
              <Route index element={<Estudantes status="indeferido" />} />
              <Route path="cadastro" element={<Estudante />} />
              <Route path="cadastro?editar=true" element={<Estudante />} />
            </Route>

            <Route path="/cadastros" element={<Cadastros />} />
            {/* FIXME - repetido */}
            <Route path="/relatorios" element={<Relatorios />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/suporte" element={<Suporte />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Route>
      </Route>
    </>
  )
);

function App() {
  const pageTitle = "SGB SEDUC TOCANTINS";

  React.useEffect(() => {
    document.title = pageTitle;
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
