import * as XLSX from "xlsx";

const DownloadExcelFinanceiro = async (bolsistas) => {
  const status = "ativo";
  const filteredData = bolsistas.filter(
    (bolsista) => bolsista.status === status
  );

  const modifiedBolsista = await Promise.all(
    filteredData.map(async (bolsista) => ({
      Credor: bolsista.cpf,
      "Valor do Item": 100,
      "Código do banco do Domicílio Bancário de Destino": bolsista.banco,
      "Código da agência do Domicílio Bancário de Destino": bolsista.agencia,
      "Domicílio Bancário de Destino": bolsista.conta,
    }))
  );

  const ws = XLSX.utils.json_to_sheet(modifiedBolsista);
  const range = XLSX.utils.decode_range(ws["!ref"]);

  for (let C = range.s.c; C <= range.e.c; ++C) {
    const address = XLSX.utils.encode_col(C) + "1";
    if (ws[address]) {
      ws[address].v = ws[address].v.toUpperCase();
      ws[address].s = { font: { bold: true } };
    }
  }

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Bolsistas");
  XLSX.writeFile(wb, "dadosparapagamento.xlsx");
};

export default DownloadExcelFinanceiro;
