import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  fetchTotalBolsistas,
  fetchTotalEscolas,
  fetchBolsistas,
  fetchProfiles,
  fetchEscolas,
  postProfile,
  fetchEscolasInep,
  fetchMunicipios,
  postEscola,
  deleteProfile,
  fetchConfiguracao,
  putConfiguracao,
  deleteEscola,
  putProfile,
  postBolsista,
  deleteBolsista,
  putEscola,
  putBolsista,
  fetchMensagens,
  putMensagem,
  postMensagem,
  deleteMensagem,
  fetchAudits,
  postAudit,
} from "../api/sgb-api";
import { useUserContext } from "./UserContext";
import Loader from "../components/loader/Loader";
import { Notify, ToastType } from "../components/notify/notify";

const DataContext = createContext();

/**
 * Componente DataProvider que fornece o contexto de dados para seus filhos.
 * @param {Object} props - As props do componente.
 * @param {React.ReactNode} props.children - Os componentes filhos.
 * @returns {JSX.Element} - O componente DataProvider.
 */
export const DataProvider = ({ children }) => {
  const { user, token } = useUserContext();
  const [inep, setInep] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isAllowedProfiles = user.profile === "ADMIN";

  const {
    data: totalBolsistas,
    isLoading: isLoadingTotalBolsistas,
    error: errorTotalBolsistas,
  } = useQuery(
    ["fetchTotalBolsistas", token],
    () => fetchTotalBolsistas(token),
    { enabled: !!token }
  );

  const { data: configuracao } = useQuery(
    ["configuracao", token],
    () => fetchConfiguracao(token),
    {
      enabled: !!token,
    }
  );

  const {
    data: totalEscolas,
    isLoading: isLoadingTotalEscolas,
    error: errorTotalEscolas,
  } = useQuery(["fetchTotalEscolas", token], () => fetchTotalEscolas(token), {
    enabled: !!token,
  });

  const {
    data: bolsistas,
    isLoading: isLoadingEstudantes,
    error: errorEstudantes,
  } = useQuery(["bolsistas", token], () => fetchBolsistas(token), {
    enabled: !!token,
  });

  const {
    data: escolas = [],
    error: errorEscolas,
    isLoading: isLoadingEscolas,
  } = useQuery(["escolas", token], () => fetchEscolas(token), {
    enabled: !!token,
  });

  const {
    data: profiles,
    isLoading: isLoadingProfiles,
    error: errorProfiles,
  } = useQuery(["profiles", token], () => fetchProfiles(token), {
    enabled: !!token && isAllowedProfiles,
  });

  const { data: audits } = useQuery(["audits", token], () => fetchAudits(token), {
    enabled: !!token && isAllowedProfiles,
  });

  const { data: municipios } = useQuery(["municipios"], () =>
    fetchMunicipios()
  );

  const { data: escolasInep } = useQuery(
    ["fetchEscolasInep", token, inep],
    () => fetchEscolasInep(token, inep),
    { enabled: !!token && !!inep }
  );

  const {
    data: mensagens,
    // isLoading: isLoadingMensagens,
    // error: errorMensagens,
  } = useQuery(["mensagens", token], () => fetchMensagens(token), {
    enabled: !!token,
  });

  /**
   * Lida com a mutação para postar um novo perfil.
   * @param {Object} data - Os dados do perfil a serem postados.
   */
  const mutationPostProfile = useMutation((data) => postProfile(token, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["profiles", token]);
      navigate("/cadastros");
    },
    onError: (error) => {
      console.error("Erro ao postar perfil:", error);
    },
  });

  /**
   * Lida com a mutação para editar um novo perfil.
   * @param {Object} data - Os dados do perfil a serem editados.
   */
  const mutationPutProfile = useMutation((data) => putProfile(token, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["profiles", token]);
      navigate("/usuarios");
    },
    onError: (error) => {
      console.error("Erro ao atualizar perfil:", error);
    },
  });

  /**
   * Lida com a mutação para deletar um perfil.
   * @param {string} id - O ID do perfil a ser deletado.
   */
  const mutationDeleteProfile = useMutation((id) => deleteProfile(token, id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["profiles", token]);
      navigate("/usuarios");
    },
    onError: (error) => {
      console.error("Erro ao deletar perfil:", error);
    },
  });

  /**
   * Lida com a mutação para atualizar a configuração.
   * @param {Object} data - Os dados da configuração a serem atualizados.
   */
  const mutationPutConfiguracao = useMutation(
    (data) => putConfiguracao(token, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["configuracao", token]);
        navigate("/pagamento");
      },
      onError: (error) => {
        console.error("Erro ao atualizar configuração:", error);
      },
    }
  );

  /**
   * Lida com a mutação para postar uma nova escola.
   * @param {Object} data - Os dados da escola a serem postados.
   */
  const mutationPostEscola = useMutation((data) => postEscola(token, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["escolas", token]);
      navigate("/cadastros");
    },
    onError: (error) => {
      console.error("Erro ao postar escola:", error);
    },
  });

  /**
   * Lida com a mutação para editar escola.
   * @param {Object} data - Os dados da escola a serem editados.
   */
  const mutationPutEscola = useMutation((data) => putEscola(token, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["escolas", token]);
      navigate("/escolas");
    },
    onError: (error) => {
      console.error("Erro ao atualizar escola:", error);
    },
  });

  /**
   * Lida com a mutação para deletar uma escola.
   * @param {string} id - O ID da escola a ser deletada.
   */
  const mutationDeleteEscola = useMutation((id) => deleteEscola(token, id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["escolas", token]);
      navigate("/escolas");
    },
    onError: (error) => {
      console.error("Erro ao deletar escola:", error);
    },
  });

  /**
   * Lida com a mutação para cadastrar um bolsista.
   * @param {Object} data - Os dados do bolsista a serem postados.
   */
  const mutationPostBolsista = useMutation(
    (data) => postBolsista(token, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bolsistas", token]);
        navigate("/estudantes");
      },
      onError: (error) => {
        console.error("Erro ao postar bolsista:", error);
      },
    }
  );

  /**
   * Lida com a mutação para editar bolsista.
   * @param {Object} data - Os dados do bolsista a serem editados.
   */
  const mutationPutBolsista = useMutation((data) => putBolsista(token, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["bolsistas", token]);
      navigate("/estudantes");
    },
    onError: (error) => {
      console.error("Erro ao atualizar bolsista:", error);
    },
  });

  /**
   * Lida com a mutação para deletar um bolsista.
   * @param {string} id - O ID do bolsista a ser deletado.
   */
  const mutationDeleteBolsista = useMutation(
    (id) => deleteBolsista(token, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bolsistas", token]);
        navigate("/estudantes");
      },
      onError: (error) => {
        console.error("Erro ao deletar bolsista:", error);
      },
    }
  );

    /**
   * Lida com a mutação para cadastrar uma mensagem.
   * @param {Object} data - Os dados da mensagem a serem postados.
   */
  const mutationPostMensagem = useMutation((data) => postMensagem(token, data), {
    onSuccess: () => {
      Notify({ message: `Registro bem-sucedido!`, type: ToastType.Success });
      queryClient.invalidateQueries(["mensagens", token]);
      navigate("/notificacao");
    },
    onError: (error) => {
      Notify({
        message: `Erro ao salvar os dados, ${error}`,
        type: ToastType.Error,
      });
      console.error("Erro ao postar mensagem:", error);
    },
  });

    /**
   * Lida com a mutação para editar mensagem.
   * @param {Object} data - Os dados da mensagem a serem editados.
   */
    const mutationPutMensagem = useMutation((data) => putMensagem(token, data), {
      onSuccess: () => {
        Notify({ message: `Edição bem-sucedida!`, type: ToastType.Success });
        queryClient.invalidateQueries(["mensagens", token]);
        navigate("/notificacao");
      },
      onError: (error) => {
        Notify({
          message: `Erro ao salvar os dados, ${error}`,
          type: ToastType.Error,
        });
        console.error("Erro ao atualizar mensagem:", error);
      },
    });

    /**
   * Lida com a mutação para deletar uma mensagem.
   * @param {string} id - O ID da mensagem a ser deletado.
   */
    const mutationDeleteMensagem = useMutation((id) => deleteMensagem(token, id), {
      onSuccess: () => {
        Notify({ message: `Remoção bem-sucedida!`, type: ToastType.Success });
        queryClient.invalidateQueries(["mensagens", token]);
        navigate("/notificacao");
      },
      onError: (error) => {
        Notify({
          message: `Erro ao remover bolsista, ${error}`,
          type: ToastType.Error,
        });
        console.error("Erro ao deletar mensagem:", error);
      },
    });

     /**
   * Lida com a mutação para cadastrar uma mensagem. */
  const mutationPostAudit = useMutation(() => postAudit(token), {
    onSuccess: () => {
      Notify({ message: `Registro bem-sucedido!`, type: ToastType.Success });
      queryClient.invalidateQueries(["audits", token]);
      navigate("/atualizacoes");
    },
    onError: (error) => {
      Notify({
        message: `Erro ao salvar os dados, ${error}`,
        type: ToastType.Error,
      });
      console.error("Erro ao postar mensagem:", error);
    },
  });

  const isLoading =
    isLoadingTotalBolsistas ||
    isLoadingTotalEscolas ||
    isLoadingEscolas ||
    isLoadingEstudantes ||
    isLoadingProfiles;
  const error =
    errorTotalBolsistas ||
    errorTotalEscolas ||
    errorEscolas ||
    errorEstudantes ||
    errorProfiles;

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <DataContext.Provider
      value={{
        totalBolsistas,
        totalEscolas,
        bolsistas,
        escolas,
        profiles,
        mutationPostProfile,
        mutationPutProfile,
        mutationDeleteProfile,
        escolasInep,
        setInep,
        municipios,
        mutationPostEscola,
        mutationDeleteEscola,
        mutationPutConfiguracao,
        mutationPostBolsista,
        mutationDeleteBolsista,
        mutationPutBolsista,
        mutationPutEscola,
        configuracao,
        mensagens,
        mutationPostMensagem,
        mutationPutMensagem,
        mutationDeleteMensagem,
        audits,
        mutationPostAudit,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

/**
 * Hook personalizado para usar o DataContext.
 * @returns {Object} - O valor do contexto.
 */
export const useDataContext = () => useContext(DataContext);
